import React, { useState } from "react";
import { Container, Grid, Paper, Typography } from "@mui/material";
import { MuiChipsInput } from "mui-chips-input";
import { AnimatedCard } from "./AnimatedCard";
import { MuiInput } from "../../../../components";
import * as casestudyService from "../../services/CasestudyService";
import { generateCompanyCards } from "./compny";
import Notification from "../../../../components/mui/Notification";
import { isEpmty } from "../../../../helper/TextHelper";
import AssistantIcon from "@mui/icons-material/AutoAwesome";
import { LoadingButton } from "@mui/lab";

const Company = ({ formValues, onInputChange, updateValues }) => {
  const baseInfoValues = formValues.baseInfo;
  const companyValues = formValues.company;
  const industryValues = formValues.industry;
  const [namesLoading, setNamesLoading] = useState();
  const [competitorsLoading, setCompetitorsLoading] = useState();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const caseStudyId = formValues.caseStudyId;

  const getNames = () => {
    setNamesLoading(true);
    casestudyService
      .getCompanyNames(
        caseStudyId,
        industryValues.types[0],
        industryValues.country,
        industryValues.province,
        baseInfoValues.marketType
      )
      .then((res) => {
        const companyCards = generateCompanyCards(res.data);
        updateValues("cards", companyCards);
        updateValues("hiddenCards", companyCards);
      })
      .catch((e) => {
        console.error(e);
        setNotify({
          isOpen: true,
          message: "Could not fetch company names. Please try again later",
          type: "error",
        });
      })
      .finally(() => {
        setNamesLoading(false);
      });
  };

  const handleCardsChange = (newChips) => {
    if (newChips.length > 0) {
      const selectedCard = newChips[newChips.length - 1];
      updateValues("names", [selectedCard]);
      if (companyValues.cards?.some((card) => card.title !== selectedCard)) {
        // seems user entered a value manually, reseting the hiddenCards
        updateValues("hiddenCards", companyValues.cards);
      }
    }
  };

  const handleCardClick = (card) => {
    const filteredCards = companyValues.cards.filter(
      (crd) => crd.title !== card.title
    );
    updateValues("names", [card.title]);
    updateValues("hiddenCards", filteredCards);
    if (companyValues.errors) {
      const targetObject = Object.assign({}, companyValues.errors);
      targetObject.type = undefined;
      updateValues("errors", targetObject);
    }
  };

  const handleDeleteCard = (chipValue, chipIndex) => {
    updateValues("names", []);

    // Check if chipValue exists in cards and not already in hiddenCards
    const existingCard = companyValues.cards.find(
      (card) => card.title === chipValue
    );

    const isCardHidden = companyValues.hiddenCards.some(
      (hiddenCard) => hiddenCard.title === chipValue
    );

    if (existingCard && !isCardHidden) {
      companyValues.hiddenCards.push(existingCard);
    }
  };

  const getCompetitors = () => {
    setCompetitorsLoading(true);
    casestudyService
      .getCompetitos(
        caseStudyId,
        companyValues.names[0],
        companyValues.territory,
        industryValues.province,
        baseInfoValues.chapters[0],
        baseInfoValues.chapterTopics?.join(", "),
      )
      .then((res) => {
        updateValues("competitorsChips", res.data);
        updateValues("competitorsHiddenChips", res.data);
      })
      .catch((e) => {
        console.error(e);
        setNotify({
          isOpen: true,
          message: "Could not fetch competitors. Please try again later",
          type: "error",
        });
      })
      .finally(() => {
        setCompetitorsLoading(false);
      });
  };

  const onCompetitorChipsClick = (card) => {
    updateValues("competitors", [...companyValues.competitors, card.title]);

    const filteredCards = companyValues.competitorsHiddenChips.filter(
      (crd) => crd.title !== card.title
    );
    updateValues("competitorsHiddenChips", filteredCards);

    if (companyValues.errors) {
      const targetObject = Object.assign({}, companyValues.errors);
      targetObject.competitors = undefined;
      updateValues("errors", targetObject);
    }
  };

  const onCompetitorChipsChange = (newChips) => {
    updateValues("competitors", newChips);
  };

  const onCompetitorChipsDelete = (chipValue, chipIndex) => {
    const filteredChips = companyValues.competitors.filter(
      (chip) => chip !== chipValue
    );
    onCompetitorChipsChange(filteredChips);

    if (
      companyValues.competitorsChips?.some((card) => card.title === chipValue)
    ) {
      // Check if the chip is not already in hiddenChips
      if (
        !companyValues.competitorsHiddenChips.some(
          (hiddenCard) => hiddenCard.title === chipValue
        )
      ) {
        companyValues.competitorsHiddenChips.push(
          companyValues.competitorsChips.find(
            (card) => card.title === chipValue
          )
        );
      }
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Paper
        variant="outlined"
        sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
      >
        <Grid
          container
          spacing={0}
          justify="center"
          alignItems="center"
          sx={{ mb: 1 }}
        >
          <Grid item>
            <Typography sx={{ fontWeight: "bold" }} variant="h6" gutterBottom>
              Company Name
            </Typography>
          </Grid>
          <Grid item style={{ marginLeft: "auto" }}>
            <LoadingButton
              disabled={companyValues.cards?.length > 0}
              sx={{ mr: 1 }}
              size="small"
              onClick={() => getNames()}
              loading={namesLoading}
              variant="outlined"
              startIcon={<AssistantIcon />}
              loadingPosition="start"
            >
              <span>Assistant</span>
            </LoadingButton>
          </Grid>

          <Grid item xs={12} sx={{ mt: 2 }}>
            <MuiChipsInput
              style={{ width: "95%" }}
              disableEdition
              hideClearAll
              value={companyValues.names}
              onDeleteChip={handleDeleteCard}
              onChange={handleCardsChange}
              error={!isEpmty(companyValues.errors?.names)}
            />
          </Grid>
        </Grid>

        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {companyValues.hiddenCards?.map((card, index) => (
            <AnimatedCard
              key={index}
              card={card}
              index={index}
              handleCardClick={handleCardClick}
            />
          ))}
        </div>
      </Paper>

      <Paper
        variant="outlined"
        sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
      >
        <React.Fragment>
          <Typography sx={{ fontWeight: "bold" }} variant="h5" gutterBottom>
            Territory
          </Typography>
          <Grid container spacing={3}>
            {/* <Grid item xs={12} sm={6}>
              <Autocomplete
                sx={{ width: 300 }}
                disablePortal
                id="combo-box-territory"
                value={
                  companyValues.territory === ""
                    ? null
                    : companyValues.territory
                }
                options={targetMarketOptions}
                onChange={(e, newValue) => updateValues("territory", newValue)}
                renderInput={(params) => (
                  <MuiInput
                    {...params}
                    required
                    error={companyValues.errors?.territory}
                    id="territory"
                    name="territory"
                    label="Market Territory"
                    fullWidth
                  />
                )}
              />
            </Grid> */}

            <Grid item xs={12} sm={6}>
              <MuiInput
                required
                error={companyValues.errors?.territory}
                id="territory"
                name="territory"
                label="Market Territory"
                value={companyValues.territory || ""}
                fullWidth
                variant="standard"
                onChange={(e) => onInputChange(e)}
                helperText="Country Name (e.g., Canada, USA)"
              />
            </Grid>
          </Grid>
        </React.Fragment>
      </Paper>

      {companyValues.names.length > 0 && !isEpmty(companyValues.territory) && (
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <Grid
            container
            spacing={0}
            justify="center"
            alignItems="center"
            sx={{ mb: 1 }}
          >
            <Grid item>
              <Typography sx={{ fontWeight: "bold" }} variant="h6" gutterBottom>
                Competitors
              </Typography>
            </Grid>
            <Grid item style={{ marginLeft: "auto" }}>
              <LoadingButton
                disabled={companyValues.competitorsChips?.length > 0}
                sx={{ mr: 1 }}
                size="small"
                onClick={() => getCompetitors()}
                loading={competitorsLoading}
                variant="outlined"
                startIcon={<AssistantIcon />}
                loadingPosition="start"
              >
                <span>Assistant</span>
              </LoadingButton>
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <MuiChipsInput
                style={{ width: "95%" }}
                hideClearAll
                value={companyValues.competitors}
                onDeleteChip={onCompetitorChipsDelete}
                onChange={onCompetitorChipsChange}
                error={!isEpmty(companyValues.errors?.competitors)}
                placeholder="Type and press enter OR select a competitor"
              />
            </Grid>
          </Grid>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {companyValues.competitorsHiddenChips?.map((card, index) => (
              <AnimatedCard
                key={index}
                card={card}
                index={index}
                handleCardClick={onCompetitorChipsClick}
              />
            ))}
          </div>
        </Paper>
      )}

      <Notification
        notify={notify}
        setNotify={setNotify}
        autoHideDuration={4000}
      />
    </Container>
  );
};

export default Company;
