import React from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import Input from "./Input";

export default function DatePicker(props) {
  const {
    name,
    label,
    value,
    error = null,
    onChange,
    variant = "outlined",
    ...other
  } = props;

  const convertToDefEventPara = (name, value) => ({
    target: {
      name,
      value,
    },
  });

  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MuiDatePicker
        label={label}
        value={value}
        onChangeRaw={handleDateChangeRaw}
        onChange={(date) => onChange(convertToDefEventPara(name, date))}
        {...other}
        renderInput={(params) => (
          <Input variant={variant} {...params} error={error} />
        )}
      />
    </LocalizationProvider>
  );
}
