export const fields = [
  { name: "names", type: "array", defaultValue: [], required: true },
  { name: "territory", type: "string", defaultValue: "", required: true },
  { name: "competitors", type: "array", defaultValue: [], required: true },
  {
    name: "marketAnalysisLevel",
    type: "string",
    defaultValue: "Company",
    required: true,
  },
];

export const label = "Company";
