import { httpClient } from "./RestService";

export const create = async (course) => {
  return await httpClient().post("/v1/education/course", course);
};

export const update = async (course) => {
  return await httpClient().put("/v1/education/course/" + course.id, course);
};

export const del = async (id) => {
  return await httpClient().delete("/v1/education/course/" + id);
};

export const getAll = async (institutionId) => {
  return await httpClient().get(
    "/v1/education/course/list?instId=" + institutionId
  );
};

export const getTextbookChapters = async (id) => {
  return await httpClient().get("/v1/education/txtbk/" + id + "/chapters");
};

export const getTextbookChapterTopics = async (
  title,
  courseName,
  major,
  textBook
) => {
  return await httpClient().get(
    "/v1/education/txtbk/chapter/topics?title=" +
      title +
      "&courseName=" +
      courseName +
      "&major=" +
      major +
      "&textBook=" +
      textBook
  );
};

export const textbooks = async () => {
  return await httpClient().get("/v1/education/txtbk/list");
};
