import React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Avatar } from "@mui/material";
import { MuiButton } from "../../../../../components";

export default function CourseCard({
  course,
  handleEdit,
  handleDelete,
  handleCasestudy,
}) {
  return (
    <Card elevation={1} sx={{ m: 0.5 }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: "#9e9e9e" }}>
            {course.field?.toUpperCase().charAt(0)}
          </Avatar>
        }
        action={
          <IconButton aria-label="edit" color="primary" onClick={handleEdit}>
            <EditOutlinedIcon />
          </IconButton>
        }
        title={course.name}
        subheader={course.studentLevel + " Students"}
      />

      <CardContent>
        <Typography
          variant="body2"
          color="textSecondary"
          style={{ whiteSpace: "pre-line" }}
        >
          {course.textBook || "No TextBook"}
        </Typography>
      </CardContent>
      <CardActions
        disableSpacing
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <MuiButton
          size="small"
          text="Case studies"
          variant="text"
          onClick={handleCasestudy}
        />
        <IconButton color="secondary" onClick={() => handleDelete(course.id)}>
          <DeleteOutlinedIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
}
