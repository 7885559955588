import React, { useState, useEffect } from "react";
import { Container, Grid, Skeleton } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import * as assignmentService from "../../services/AssignmentService";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import Notification from "../../../../components/mui/Notification";
import PageHeader from "../../../../components/PageHeader";
import AssignmentCard from "./AssignmentCard";
import {
  CASE_STUDY_ASSIGNMENT_GROUPS,
  CASE_STUDY_ASSIGNMENT_NEW,
  CASE_STUDY_ASSIGNMENT_STUDENTS,
} from "../../helper/Constants";
import NewCard from "../../../../components/NewCard";

export default function List() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [refresh, setRefresh] = useState(false);

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [loading, setLoading] = useState(true);
  const [assignments, setAssignments] = useState([]);
  const casestudy = state.casestudy;

  useEffect(() => {
    assignmentService
      .getAllAssignments(casestudy.id)
      .then((response) => {
        setAssignments(response.data);
      })
      .catch((e) => {
        console.error(`Error: ${e.message}`);
        setNotify({
          isOpen: true,
          message: "Could not connect to server. Please try again later.",
          type: "error",
        });
      })
      .finally(() => setLoading(false));
  }, [refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  const submitDueDate = (id, newDate) => {
    assignmentService
      .updateDueDate(id, newDate)
      .then((response) => {
        setAssignments((prevRecords) =>
          prevRecords.map((record) =>
            record.id === id
              ? {
                  ...record,
                  dueDate: response.data,
                }
              : record
          )
        );
        setNotify({
          isOpen: true,
          message: "Due date Updated Successfully",
          type: "success",
        });
      })
      .catch((e) => {
        console.error(`Error: ${e.message}`);
        setNotify({
          isOpen: true,
          message: "Could not update due date. Please try again later.",
          type: "error",
        });
      });
  };

  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    assignmentService
      .deleletAssignment(id)
      .then(() => {
        const newList = assignments.filter((item) => item.id !== id);
        setAssignments(newList);
        setNotify({
          isOpen: true,
          message: "Deleted Successfully",
          type: "success",
        });
      })
      .catch((e) => {
        console.error(`Error: ${e.message}`);
        setNotify({
          isOpen: true,
          message: "Could not delete a case study. Please try again later.",
          type: "error",
        });
      });
  };

  const handleGroups = (assignment) => {
    if ("GROUP" === assignment.type) {
      navigate(CASE_STUDY_ASSIGNMENT_GROUPS, {
        state: { assignment },
      });
    } else {
      navigate(CASE_STUDY_ASSIGNMENT_STUDENTS, {
        state: { assignment, casestudyId: casestudy.id },
      });
    }
  };

  const handleDelete = (id) => {
    setConfirmDialog({
      isOpen: true,
      title: "Are you sure to delete this record?",
      subTitle: "You can't undo this operation",
      onConfirm: () => {
        onDelete(id);
      },
    });
  };

  const onRefresh = () => {
    setRefresh(!refresh);
  };

  const handleNew = () => {
    navigate(CASE_STUDY_ASSIGNMENT_NEW, { state: { casestudy } });
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <PageHeader
        title="List of Projects"
        isChild={true}
        onRefresh={() => onRefresh()}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <NewCard title={"New Project"} onclick={handleNew} />
        </Grid>
        {loading
          ? Array.from(new Array(4)).map((item, index) => (
              <Grid
                key={index}
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                sx={{ mt: 2 }}
              >
                <Skeleton variant="rectangular" height={118} />
                <Skeleton />
                <Skeleton width="60%" />
              </Grid>
            ))
          : assignments.map((assignment, index) => (
              <Grid key={index} item xs={12} sm={6} md={4}>
                <AssignmentCard
                  assignment={assignment}
                  handleNotification={() => {
                    setNotify({
                      isOpen: true,
                      message: "Successfully Copied to clipboard",
                      type: "success",
                    });
                  }}
                  updateDueDate={(newDueDate) =>
                    submitDueDate(assignment.id, newDueDate)
                  }
                  handleGroups={() => handleGroups(assignment)}
                  handleDelete={() => handleDelete(assignment.id)}
                />
              </Grid>
            ))}
      </Grid>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Container>
  );
}
