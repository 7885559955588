import React, { useState } from "react";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { Box, Card, Typography } from "@mui/material";
import IconWithText from "../../../../components/IconWithText";
import { upperCaseFirstLetter } from "../../../../helper/TextHelper";
import { MuiButton, MuiDatePicker } from "../../../../components";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import EditIcon from "@mui/icons-material/EditOutlined";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { parseISO, format } from "date-fns";

export default function AssignmentCard({
  assignment,
  handleNotification,
  handleDelete,
  handleGroups,
  updateDueDate,
}) {
  const [dueDateState, setDueDateState] = useState({
    isEditing: false,
    updatedValue: undefined,
    error: undefined,
  });
  const copyToClipboard = (text) => {
    // Use navigator clipboard API if it's supported
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text);
      handleNotification();
    } else {
      // Fallback: create a temporary textarea to copy the text
      const textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      handleNotification();
    }
  };

  const btnText = () => {
    return "GROUP" === assignment.type ? "Groups" : "Students";
  };

  const editDueDate = (dueDate) => {
    if (!dueDate) {
      setDueDateState((prevState) => ({
        ...prevState,
        error: "Can not be empty",
      }));
    } else {
      setDueDateState((prevState) => ({
        ...prevState,
        updatedValue: dueDate,
        error: undefined,
      }));
    }
  };

  return (
    <Card elevation={1} sx={{ m: 0.5 }}>
      <CardHeader
        title={
          <Typography
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
            }}
          >
            {upperCaseFirstLetter(assignment.title)}
          </Typography>
        }
        subheader={
          dueDateState.isEditing ? (
            <Box sx={{ display: "flex", alignItems: "center", gap: 1, mt: 1 }}>
              <MuiDatePicker
                minDate={new Date()}
                openTo="day"
                views={["year", "month", "day"]}
                name="dueDate"
                variant="standard"
                value={
                  dueDateState.updatedValue || parseISO(assignment.dueDate)
                }
                onChange={(e) => editDueDate(e.target.value)}
                error={dueDateState.error}
              />
              <IconButton
                size="small"
                color="success"
                onClick={() => {
                  setDueDateState(() => ({
                    isEditing: false,
                    updatedValue: undefined,
                    error: undefined,
                  }));
                  updateDueDate(
                    format(dueDateState.updatedValue, "yyyy-MM-dd")
                  );
                }}
              >
                <CheckIcon fontSize="small" />
              </IconButton>
              <IconButton
                size="small"
                color="primary"
                onClick={() =>
                  setDueDateState(() => ({
                    isEditing: false,
                    updatedValue: undefined,
                    error: undefined,
                  }))
                }
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          ) : (
            <Box sx={{ display: "flex", alignItems: "center", gap: 0 }}>
              <Typography variant="body2" color="text.secondary">
                {"Due date: " + assignment.dueDate}
              </Typography>
              <IconButton
                color="primary"
                onClick={() =>
                  setDueDateState(() => ({
                    isEditing: true,
                    updatedValue: undefined,
                    error: undefined,
                  }))
                }
                size="small"
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Box>
          )
        }
      />

      <CardContent>
        <IconWithText
          icon={<ContentCopyIcon />}
          text={assignment.id}
          onClick={() => copyToClipboard(assignment.id)}
        />
        {assignment.promoCode && (
          <IconWithText
            icon={<MoneyOffIcon />}
            text={assignment.promoCode}
            onClick={() => copyToClipboard(assignment.promoCode)}
          />
        )}
      </CardContent>

      <CardActions
        disableSpacing
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <MuiButton
          size="small"
          text={btnText()}
          variant="text"
          onClick={handleGroups}
        />
        <IconButton color="secondary" onClick={handleDelete}>
          <DeleteOutlinedIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
}
